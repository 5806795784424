// Login
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const WORK_FLOW_LIST = "WORK_FLOW_LIST";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT="LOGOUT"


export const START_LOADING="START_LOADING"
export const STOP_LOADING="STOP_LOADING"
export const STATUS_CODES = {
    HTTP_400: 400,
    HTTP_401: 401,
    HTTP_403: 403,
    HTTP_404: 404,
    HTTP_409: 409,
    HTTP_422: 422,
    HTTP_500: 500,
    HTTP_501: 501,
};


export const DemoMeetingEnum = {"START":1, "END":2, "IDEAL":3,"DISABLE":4}
Object.freeze(DemoMeetingEnum);

export const DemoSocketEnum = {"START":100, "END":101, "FINAL":102,"LIVE":103}
Object.freeze(DemoSocketEnum);


