import {
    START_LOADING,
    STOP_LOADING
} from "../Utils/Constants";

export const commonInitialState = {
    isLoading: false,
};

export const commonReducer = (state, action) => {

    if (typeof state === 'undefined') {
        return commonInitialState
    }

    switch (action.type) {

        case START_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case STOP_LOADING:
            return {
                ...state,
                isLoading: false
            }
        default:
            return state;

    }

};
